<template>
    <v-container fluid>
        <v-form @submit.prevent="getFitnessMains">
            <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'fitness_main.create'}" color="indigo" dark fab fixed top
                   right class="v-btn--add-form-top">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="10">
                                <v-subheader class="headline">{{ $t('search_fitness_main') }}</v-subheader>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="2" class="text-right">
                                <v-btn :to="{name: 'fitness_main.create'}" dark color="indigo">
                                    {{ $t('create') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="fitness_main" rules="min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="fitness_main" type="text" :error-messages="errors"
                                                  :disabled="loading" :label="$t('fitness_main')"
                                                  prepend-icon="mdi-view-list"
                                                  color="primary" clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4">
                        <v-row>
                            <v-col cols="12" class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading"
                                       :block="$vuetify.breakpoint.xsOnly" color="primary">
                                    {{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text>
                <v-data-table item-key="id" ref="table" :headers="headers" :items="fitness_mainItems"
                              :options.sync="options" disable-pagination
                              :sort-by.sync="sortBy" :sort-desc.sync="sortDir"
                              :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')"
                              class="sortable-table elevation-1"
                              hide-default-footer>
                    <template v-slot:item.sort>
                        <v-btn icon class="handle">
                            <v-icon>mdi-drag-horizontal</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="mr-2" @click="editFitnessMain(item)" :title="$t('edit')">
                                    mdi-square-edit-outline
                                </v-icon>
                            </template>
                            <span>{{$t('edit')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="deleteFitnessMain(item)" :title="$t('delete')">
                                    mdi-delete-outline
                                </v-icon>
                            </template>
                            <span>{{$t('delete')}}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import Sortable from 'sortablejs'
    import {mapGetters} from "vuex"

    export default {
        name: "FitnessMains",
        components: {
            ValidationProvider,
            ValidationObserver
        },
        inject: ['forceRerender'],
        data() {
            return {
                fitness_main: null,
                loading: false,
                sortBy: "sort",
                sortDir: false,
                options: {},
                fitness_mainItems: [],
                headers: [
                    {
                        text: "",
                        align: "left",
                        sortable: false,
                        value: "sort",
                    },
                    {
                        text: this.$t('name'),
                        align: "left",
                        sortable: false,
                        value: "name"
                    },
                    {
                        text: this.$t('actions'),
                        align: "center",
                        value: 'action',
                        sortable: false,
                        width: 120,
                    },
                ],
            }
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage']),
        },
        mounted() {

            let table = this.$refs.table.$el.querySelector("tbody")
            Sortable.create(table, {
                handle: ".handle",
                onEnd: this.dragReorder
            })
        },
        watch: {
            options: {
                handler() {
                    this.getFitnessMains()
                },
                deep: false
            }
        },
        methods: {
            dragReorder({newIndex, oldIndex}) {
                const rowSelected = this.fitness_mainItems.splice(oldIndex, 1)[0]
                this.fitness_mainItems.splice(newIndex, 0, rowSelected)
                this.saveSortFitnessMain()
            },
            async saveSortFitnessMain() {
                var _this = this
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.fitness_mainItems && this.fitness_mainItems.length > 0) {
                    for (let i in this.fitness_mainItems) {
                        if (this.fitness_mainItems[i].id !== 'undefined' && this.fitness_mainItems[i].id > 0) {
                            formData.append(`sort[${i}]`, this.fitness_mainItems[i].id)
                        }
                    }
                }
                await this.$http
                    .post(`admin/fitness_main/sort`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('fitness_main_sorting_updated'))
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('fitness_main_sorting_not_updated'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            editFitnessMain(item) {
                this.$router.push({
                    name: 'fitness_main.edit',
                    params: {
                        id: item.id
                    }
                })
            },
            async getFitnessMains() {
                var _this = this
                this.progress = 0
                this.loading = true
                let params = {}
                if (this.fitness_main) {
                    params.fitness_main = this.fitness_main
                }
                await this.$http
                    .get("admin/fitness_main", {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.fitness_mainItems = res.body.data
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_list_fitness_mains'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async deleteFitnessMain(item) {
                if (confirm(this.$t('delete_fitness_main'))) {
                    var _this = this
                    this.loading = true
                    await this.$http
                        .delete(`admin/fitness_main/${item.id}`)
                        .then(res => {
                            this.$toastr.success(this.$t('fitness_main_has_been_deleted'))
                            this.getFitnessMains()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('fitness_main_has_not_been_deleted'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            }
        }
    }
</script>
